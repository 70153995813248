.rss-list {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-flex-align: center;
  justify-content: flex-start;
  -ms-flex-pack: start;
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    text-decoration: none;
    cursor: pointer;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      * {
        fill: #95A4C9;
        transition: all .2s linear;
      }
    }

    &:hover svg * {
      fill: #FFF;
    }
  }

  li {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    &:nth-child(n+2) {
      margin-left: 16px;
    }
  }
}